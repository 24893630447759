import React, { useState, useEffect } from "react";
import "../css/reports.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../css/createReport.css";
import BlockedCounterList from "../pages/BlockedCounterList";
import DoctorProductMasterBulk from "../pages/DoctorProductMasterBulk";

const Reports = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Select Report Option");

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleMenuItemClick = (event, item) => {
    setSelectedItem(item);
    setMenuOpen(false);
  };

  return (
    <div className="reports_container">
      <h1>---| Create New Report |---</h1>
      <br />
      <div className="report_dropdown">
        <ul>
          <li className="dropdown">
            <div className="dropdown-button" onClick={handleToggleMenu}>
              {selectedItem || "Select an item"} <ExpandMoreIcon />
            </div>
            {menuOpen && (
              <ul className="dropdown-menu">
                <li
                  onClick={(event) =>
                    handleMenuItemClick(
                      event,
                      "Doctor Product Master Bulk report"
                    )
                  }
                >
                  Doctor Product Master Bulk report
                </li>

                <li
                  onClick={(event) =>
                    handleMenuItemClick(event, "Blocked Counter List")
                  }
                >
                  Blocked Counter List
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      {selectedItem === "Doctor Product Master Bulk report" && (
        <DoctorProductMasterBulk />
      )}
      {selectedItem === "Blocked Counter List" && <BlockedCounterList />}
    </div>
  );
};

export default Reports;
