import React, { useMemo, useState, useEffect } from "react";
//MRT Imports
import MaterialReactTable from "material-react-table";

// import { data } from './makeData';
import { Container } from "@material-ui/core";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";

import { cognito } from "../utils/aws";

// All employee API
const url =
  "https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/admin/pharmacies";
// const url = "http://localhost:8000/api/employee/all";

const Pharmacies = () => {
  const notify = () =>
    toast("Unauthorized access..!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "warning",
    });

  const cookies = new Cookies();

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserData();
  }, [url]);

  const getUserData = async () => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: idToken,
      },
    });
    if (response.status === 401) {
      cognito.refreshToken(refreshToken);
      console.log(response);
      getUserData();
    } else {
      const jsonData = await response.json();
      setUserData(jsonData.data);
      console.log("Users Data => ", jsonData.data);
      setLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      id: "pharmacies", //id used to define `group` column
      columns: [
        {
          accessorKey: "name", //accessorFn used to join multiple data into a single cell
          id: "name", //id is still required when using accessorFn instead of accessorKey
          header: "Name",
          size: 250,
        },
        {
          accessorKey: "email", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Email",
          size: 300,
        },
        {
          accessorKey: "code", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Code",
          size: 200,
        },
      ],
    },
  ]);

  return (
    <div className="mb-5">
      <ToastContainer />
      <h2 style={{ marginTop: 20, textAlign: "center" }}>Pharmacies</h2>
      <Container>
        <MaterialReactTable
          columns={columns}
          data={userData}
          enableColumnFilterModes
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          initialState={{ showColumnFilters: false, density: "compact" }}
          positionToolbarAlertBanner="bottom"
          state={
            userData.length > 0 ? { isLoading: false } : { isLoading: true }
          }
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#3246c41a",
              },
            },
          }}
        />
      </Container>
    </div>
  );
};

export default Pharmacies;
