import Dashboard from "./pages/Dashboard";
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div>
      <Routes>
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;

// import Dashboard from "./pages/Dashboard";

// const getPage = (page) => {
//   switch (page) {
//     case "dashboard":
//       return <Dashboard />;
//     default:
//       return <Dashboard />;
//   }
// };

// function App() {
//   const page = useSelector((state) => state.page.page);

//   const currentPage = getPage(page);
//   return <div>{currentPage}</div>;
// }

// export default App;

