import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../css/navbar.css";

function MenuItem(props) {
  const [showSubmenu, setShowSubmenu] = useState(false);

  console.log("Menu data", props);

  const handleItemClick = () => {
    if (props.menuItem.submenus) {
      setShowSubmenu(!showSubmenu);
    }
  };

  return (
    <>
      <li className="dropdown" onClick={handleItemClick}>
        <span className={`menu-icon fa fa-${props.menuItem.icon}`}></span>
        {props.menuItem.submenus ? (
          <span className="menu-text">{props.menuItem.name}</span>
        ) : (
          <Link to={props.menuItem.menu_url} className="singleMenu">
            <span className="menu-text">{props.menuItem.name}</span>
          </Link>
        )}

        {props.menuItem.submenus && (
          <span
            className={`submenu-icon fa fa-angle-${
              showSubmenu ? "up" : "down"
            }`}
          ></span>
        )}

        {showSubmenu && props.menuItem.submenus && (
          <ul className="dropdown-menu">
            {props.menuItem.submenus.map((submenu) => {
              if (submenu.allowedRoles.includes(props.userRole)) {
                return (
                  <Link to={submenu.menu_url} className="dm">
                    <li
                      style={{ left: "1rem" }}
                      key={submenu.name}
                      className="dm"
                    >
                      {submenu.name}
                    </li>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </ul>
        )}
      </li>
    </>
  );
}

export default MenuItem;
