import React, { useState, useEffect } from "react";
import "../css/reports.css";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "../css/createReport.css";

import Cookies from "universal-cookie";
import verifyAccessToken from "../utils/verifyToken";

import { styled } from "@mui/material/styles";

import { cognito } from "../utils/aws";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const company_url =
  "https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/admin/companies";

const products_url =
  "https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/admin/products";

const employee_url =
  "https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/admin/employees";

const indianStates = ["Andhra Pradesh", "Arunachal Pradesh", "Assam"];
const reportType = ["With SKU", "Without SKU"];

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-clearIndicator": {
    display: "none",
  },
});

const Reports = () => {
  const cookies = new Cookies();

  const notify = () =>
    toast("Report generation rquest send check after 30 Minutes..!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "info",
    });

  const notify1 = () =>
    toast("All fields are required..!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "warning",
    });

  const notify2 = () =>
    toast("Authorization error ..!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "warning",
    });

  const [disabled, setDisabled] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [divsionsData, setDivisionsData] = useState([]);

  const [selectCompanyName, setSelectCompanyName] = useState("");
  const [selectDivision, setSelectDivision] = useState([]);
  const [selectedIndianStates, setSelectedIndianStates] = useState([]);
  const [selectReportType, setSelectReportType] = useState("");
  const [companyNameList, setCompanyNameList] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [productsListData, setProductsListData] = useState([]);
  const [selectedProductsData, setSelectedProductsData] = useState([]);

  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployeesL0, setSelectedEmployeesL0] = useState([]);
  const [selectedEmployeesL1, setSelectedEmployeesL1] = useState([]);
  const [selectedEmployeesL2, setSelectedEmployeesL2] = useState([]);
  const [selectedEmployeesL3, setSelectedEmployeesL3] = useState([]);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [employeeDataListL0, setEmployeeDataListL0] = useState([]);
  const [employeeDataListL1, setEmployeeDataListL1] = useState([]);
  const [employeeDataListL2, setEmployeeDataListL2] = useState([]);
  const [employeeDataListL3, setEmployeeDataListL3] = useState([]);

  useEffect(() => {
    getCompaniesData();
    getProductsData();
    getEmployeeData();
  }, [company_url]);

  useEffect(() => {
    if (selectDivision.length > 0) {
      const pName = productsData.filter((singleProduct) =>
        selectDivision.includes(singleProduct.division_id[0])
      );
      const prodName = pName.map((singleProduct) => singleProduct.name);
      setProductsListData(prodName);
    }
  }, [selectDivision]);

  const getCompaniesData = async () => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");
    const response = await fetch(company_url, {
      method: "GET",
      headers: {
        Authorization: idToken,
      },
    });

    if (response.status === 401) {
      cognito.refreshToken(refreshToken);
      console.log(response);
      getCompaniesData();
    } else {
      const jsonData = await response.json();
      setCompaniesData(jsonData.data);
      console.log(jsonData.data);
      // console.log(jsonData.data[0].divisions);
      setDivisionsData(jsonData.data[0].divisions);
      setSelectCompanyName(jsonData.data[0].name);
      const cName = jsonData.data.map((singleCompany) => singleCompany.name);
      setCompanyNameList(cName);
    }
  };

  const getProductsData = async () => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");

    const response = await fetch(products_url, {
      method: "GET",
      headers: {
        Authorization: idToken,
      },
    });
    if (response.status === 401) {
      cognito.refreshToken(refreshToken);
      console.log(response);
      getProductsData();
    } else {
      const jsonData = await response.json();
      setProductsData(jsonData.data);
      console.log("Products Data => ", jsonData.data);
      // console.log(jsonData.data[0].divisions);
    }
  };

  const getEmployeeData = async () => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");
    const response = await fetch(employee_url, {
      method: "GET",
      headers: {
        Authorization: idToken,
      },
    });

    if (response.status === 401) {
      cognito.refreshToken(refreshToken);
      console.log(response);
      getEmployeeData();
    } else {
      const jsonData = await response.json();
      setEmployeeData(jsonData.data);
      console.log("Employee Data => ", jsonData.data);
      // console.log(jsonData.data[0].divisions);
      const eName3 = jsonData.data.filter(
        (singleEmp) => singleEmp.designation == "SM"
      );
      const empL3 = eName3.map((singleEmp) => singleEmp.name);
      setEmployeeDataListL3(empL3);

      const eName2 = jsonData.data.filter(
        (singleEmp) => singleEmp.designation == "RSM"
      );
      const empL2 = eName2.map((singleEmp) => singleEmp.name);
      setEmployeeDataListL2(empL2);

      const eName1 = jsonData.data.filter(
        (singleEmp) => singleEmp.designation == "ASM"
      );
      const empL1 = eName1.map((singleEmp) => singleEmp.name);
      setEmployeeDataListL1(empL1);

      const eName0 = jsonData.data.filter(
        (singleEmp) => singleEmp.designation == "PSR"
      );
      const empL0 = eName0.map((singleEmp) => singleEmp.name);
      setEmployeeDataListL0(empL0);
    }
  };

  useEffect(() => {
    if (companiesData.length > 0) {
      const cName = companiesData.filter(
        (singleCompany) => singleCompany.name == selectCompanyName
      );

      setDivisionsData(cName[0].divisions);
    }
  }, [selectCompanyName]);

  const send_message = async () => {
    if (
      selectDivision.length > 0 &&
      selectedIndianStates.length > 0 &&
      selectReportType.length > 0
    ) {
      const idToken = cookies.get("idToken");
      const decodedIdToken = await verifyAccessToken(idToken, "id");
      // console.log('decoded', decodedIdToken);
      const cName = companiesData.filter(
        (ele) => ele.name == selectCompanyName
      );
      console.log("cName --> ", cName);
      console.log("companyNameList --> ", companyNameList);

      const employees = employeeData.filter((singleEmployee) =>
        selectedEmployeesL0.includes(singleEmployee.name)
      );
      const position_code = employees.map(
        (singleEmployee) => singleEmployee.position_code
      );

      const response = await fetch(
        "https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/reports/generate",
        {
          method: "POST",
          headers: {
            Authorization: idToken,
          },
          body: JSON.stringify({
            company_id: cName[0]._id,
            division_id: selectDivision,
            state: selectedIndianStates,
            report: "doctorProductMasterBulk",
            report_type: selectReportType,
            employees: position_code,
          }),
        }
      );
      const refreshToken = cookies.get("refreshToken");
      if (response.status === 401) {
        cognito.refreshToken(refreshToken);
        console.log(response);
        send_message();
      } else {
        console.log("response", response);
        notify();
        setDisabled(true);
        setTimeout(() => {
          setDisabled(false);
        }, 10000);
      }

      if (response.status !== 200) {
        notify2();
      } else {
      }
    } else {
      notify1();
    }
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectCompanyName(newValue);
  };

  // console.log("Company Name ==>", selectCompanyName);
  // console.log(selectedIndianStates);
  // console.log(selectDivision);
  // console.log(selectReportType);

  return (
    <div>
      <br />
      <div className="report_form">
        {/* L0 - L5 Inputs starts */}

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={employeeDataListL3}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedEmployeesL3([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="L3 Employees"
                placeholder="L3 Employees"
              />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={employeeDataListL2}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedEmployeesL2([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="L2 Employees"
                placeholder="Employees"
              />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={employeeDataListL1}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedEmployeesL1([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="L1 Employees"
                placeholder="Employees"
              />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={employeeDataListL0}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedEmployeesL0([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="L0 Employees"
                placeholder="Employees"
              />
            )}
          />
        </div>

        {/* L0 - L5 Inputs starts */}

        <div>
          <StyledAutocomplete
            clearOnEscape
            id="filter-demo"
            value={selectCompanyName}
            options={companyNameList}
            getOptionLabel={(option) => option}
            filterOptions={filterOptions}
            onChange={handleCompanyChange}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
                label="Company Name"
                // style={{ width: 300 }}
              />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={divsionsData}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectDivision([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 0 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Divisions"
                placeholder="Divisions"
              />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={productsListData}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedProductsData([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 0 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Products" placeholder="Products" />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={indianStates}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedIndianStates([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="State" placeholder="State" />
            )}
          />
        </div>

        <div>
          <Autocomplete
            id="filter-demo"
            options={reportType}
            getOptionLabel={(option) => option}
            filterOptions={filterOptions}
            onChange={(event, newValue) => {
              setSelectReportType(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Report Type" />
            )}
          />
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <Button
          disabled={disabled}
          onClick={send_message}
          variant="outlined"
          startIcon={<DescriptionOutlinedIcon />}
        >
          Generate Report
        </Button>
      </div>
    </div>
  );
};

export default Reports;

const employees = ["Emp1", "Emp2", "Emp3"];
const empLevel = [
  "PSR - L0",
  "ASM - L1",
  "RSM - L2",
  "SM - L3",
  "NSM - L4",
  "DH - L5",
  "CH - L6",
  "SCH - L7",
  "GH - L8",
  "SGH - L9",
  "MD - L10",
];
