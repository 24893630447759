import React, { useMemo, useState, useEffect } from "react";
import { cognitoIdp } from "../utils/UserPool"; //MRT Imports
import MaterialReactTable from "material-react-table";
//Material-UI Imports
import { Box, Button } from "@mui/material";
// import { data } from './makeData';
import { Container } from "@material-ui/core";
// All employee API
import { ToastContainer, toast } from "react-toastify";

const Users = () => {
  const notify = () =>
    toast("Unauthorized access..!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "warning",
    });

  const [userData, setUserData] = useState([]);
  const [flag, setFlag] = useState(true);

  ///////////////////   Cognito Users List ///////////////
  const getUserData = () => {
    var params = {
      UserPoolId: process.env.REACT_APP_USERPOOL_ID /* required */,
    };
    cognitoIdp.listUsers(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } // an error occurred
      else {
        console.log(data.Users);
        setUserData(data.Users);
        console.log(data.Users);
      } // successful response
    });
  };

  useEffect(() => {
    getUserData();
  }, [flag]);

  const columns = useMemo(() => [
    {
      id: "employee", //id used to define `group` column
      columns: [
        {
          accessorKey: "Username", //accessorFn used to join multiple data into a single cell
          id: "name", //id is still required when using accessorFn instead of accessorKey
          header: "Name",
          size: 250,
        },
        {
          accessorKey: "Enabled",
          // filterVariant: 'range',
          header: "Active Status",
          size: 200,
          //custom conditional format and styling
          Cell: ({ cell }) => (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue() === false
                    ? theme.palette.error.dark
                    : theme.palette.success.dark,
                borderRadius: "0.25rem",
                color: "#fff",
                maxWidth: "9ch",
                p: "0.25rem",
              })}
            >
              {cell.getValue()?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Box>
          ),
        },
      ],
    },
  ]);

  return (
    <div className="mb-5">
      <ToastContainer />
      <h2 style={{ marginTop: 20, textAlign: "center" }}>Cognito Users</h2>
      <Container>
        <MaterialReactTable
          columns={columns}
          data={userData}
          enableColumnFilterModes
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          initialState={{ showColumnFilters: false, density: "compact" }}
          positionToolbarAlertBanner="bottom"
          state={
            userData.length > 0 ? { isLoading: false } : { isLoading: true }
          }
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#3246c41a",
              },
            },
          }}
          renderTopToolbarCustomActions={({ table }) => {
            const handleDeactivate = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                var params = {
                  UserPoolId: process.env.REACT_APP_USERPOOL_ID /* required */,
                  Username: row.getValue("name") /* required */,
                };
                cognitoIdp.adminDisableUser(params, function (err, data) {
                  if (err) console.log(err, err.stack); // an error occurred
                  // console.log(data);
                  else setFlag(!flag); // successful response
                });
              });
            };

            const handleActivate = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                var params = {
                  UserPoolId: process.env.REACT_APP_USERPOOL_ID /* required */,
                  Username: row.getValue("name") /* required */,
                };
                cognitoIdp.adminEnableUser(params, function (err, data) {
                  if (err) console.log(err, err.stack); // an error occurred
                  // console.log(data);
                  else setFlag(!flag); // successful response
                });
              });
            };
            return (
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <Button
                  color="error"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleDeactivate}
                  variant="contained"
                >
                  Deactivate
                </Button>
                <Button
                  color="primary"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleActivate}
                  variant="contained"
                >
                  Activate
                </Button>
              </div>
            );
          }}
        />
      </Container>
    </div>
  );
};

export default Users;
