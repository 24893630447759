import { CognitoJwtVerifier } from "aws-jwt-verify";

// Verifier that expects valid access tokens:
const verifierAccessToken = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  tokenUse: "access",
  clientId: process.env.REACT_APP_CLIENT_ID,
});
const verifierIdToken = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  tokenUse: "id",
  clientId: process.env.REACT_APP_CLIENT_ID,
});
const verifierRefreshToken = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  tokenUse: "refresh",
  clientId: process.env.REACT_APP_CLIENT_ID,
});
// Verify the access token signature

export default async function verifyAccessToken(accessToken, type) {
  try {
    if (type === "access") {
      const payload = await verifierAccessToken.verify(accessToken);
      return payload;
    } else if (type === "id") {
      const payload = await verifierIdToken.verify(accessToken);
      return payload;
    } else if (type === "refresh") {
      const payload = await verifierRefreshToken.verify(accessToken);
      return payload;
    }
  } catch {
    console.log("Token not valid!");
  }
}
