import { Route, Routes, Link } from "react-router-dom";
import Cookies from "universal-cookie";

import "../css/navbar.css";
import Logo from "../assets/medvol.png";

import Users from "../tables/Users";
import Products from "../tables/Products";
import Divisions from "../tables/Divisions";
import Companies from "../tables/Companies";
import CognitoUsers from "../tables/CognitoUsers";
import Pharmacies from "../tables/Pharmacies";
import Doctors from "../tables/Doctors";
import CreateReports from "../pages/CreateReports";

import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import GeneratedReports from "../pages/GeneratedReports";
import MenuItem from "./MenuItems";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
    alignItems: "center",
  },
  logo: {
    flexGrow: "1",
  },
  medvolLogo: {
    width: "100px",
  },

  logout: {
    textDecoration: "none",
    color: "black",
    fontSize: "20px",
    padding: " 7px 15px",
    marginLeft: "20px",
    borderRadius: "20px",
    transition: "0.3s",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "16px",
    padding: " 7px 10px",
    marginLeft: theme.spacing(2),
    borderRadius: "20px",
    transition: "0.3s",
    "&:hover": {
      color: "white",
      backgroundColor: "#3246c4",
    },
  },
}));

function Navbar(props) {
  const cookies = new Cookies();
  const logoutHandler = () => {
    cookies.remove("token");
    cookies.remove("idToken");
    cookies.remove("refreshToken");
    window.location.href =
      process.env.REACT_APP_AUTH_URL +
      "/logout" +
      "?response_type=token&client_id=" +
      process.env.REACT_APP_CLIENT_ID +
      "&redirect_uri=" +
      process.env.REACT_APP_URL;
  };

  const classes = useStyles();

  return (
    <AppBar position="static" color="transparent">
      <CssBaseline />
      <Toolbar>
        <Typography variant="h4" className={classes.logo}>
          <Link to="/">
            <img src={Logo} alt="Medvol Logo" className={classes.medvolLogo} />
          </Link>
        </Typography>
        <div className={classes.navlinks}>
          <nav>
            <ul className="menu">
              {props.data.map(
                (menuItem) =>
                  menuItem.allowedRoles.includes(props.userRole) && (
                    <MenuItem
                      key={menuItem.name}
                      menuItem={menuItem}
                      userRole={props.userRole}
                    />
                  )
              )}
            </ul>
          </nav>

          <Button
            style={{ marginLeft: "20px" }}
            onClick={logoutHandler}
            className={classes.logout}
            variant="outlined"
            color="error"
          >
            Logout
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

function Content() {
  return (
    <>
      <Routes>
        <Route path="/users" element={<Users />} />
        <Route path="/doctors" element={<Doctors />} />
        <Route path="/pharmacies" element={<Pharmacies />} />
        <Route path="/products" element={<Products />} />
        <Route path="/divisions" element={<Divisions />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/cognitousers" element={<CognitoUsers />} />
        <Route path="/create-reports" element={<CreateReports />} />
        <Route path="/generated-reports" element={<GeneratedReports />} />
      </Routes>
    </>
  );
}

export { Navbar, Content };
