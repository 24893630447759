import React, { useEffect, useState } from "react";
import { Navbar, Content } from "../components/Navbar";
import { useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import verifyAccessToken from "../utils/verifyToken";
import { cognito } from "../utils/aws";

const Dashboard = () => {
  const cookies = new Cookies();
  const [searchParams] = useSearchParams();
  const [userRole, setUserRole] = useState("");
  const [userRole1, setUserRole1] = useState("Management");

  useEffect(() => {
    if (
      searchParams.get("token") &&
      searchParams.get("idToken") &&
      searchParams.get("refreshToken")
    ) {
      cookies.set("token", searchParams.get("token"));
      cookies.set("idToken", searchParams.get("idToken"));
      cookies.set("refreshToken", searchParams.get("refreshToken"));
    }
    if (!cookies.get("token") || !cookies.get("idToken")) {
      window.location.href =
        process.env.REACT_APP_AUTH_URL +
        "?response_type=token&client_id=" +
        process.env.REACT_APP_CLIENT_ID +
        "&redirect_uri=" +
        window.location.href +
        "&state=123456&scope=openid+email+phone+profile";
      // window.location.href = 'http://localhost:3000/oauth2/authorize?response_type=token&client_id=vavsjgvje550l9vbmlir3g6fj&redirect_uri='+window.location.href+'&state=123456&scope=openid+email+phone+profile'
    } else {
      getUserToken();
    }
  }, []);

  const getUserToken = async () => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");
    const decodedIdToken = await verifyAccessToken(idToken, "id");
    console.log("decoded Id token", decodedIdToken);

    if (!decodedIdToken) {
      cognito.refreshToken(refreshToken);
      // console.log(response);
      getUserToken();
    }
    setUserRole(decodedIdToken["custom:role"]);
  };

  return (
    <div>
      <Navbar data={data} userRole={userRole} />
      <div>{"User role: " + userRole}</div>
      <Content />
    </div>
  );
};

const data = [
  {
    name: "Companies",
    allowedRoles: ["Management"],
    submenus: [
      {
        name: "Companies",
        menu_url: "/companies",
        allowedRoles: ["Management"],
      },
      {
        name: "Divisions",
        menu_url: "/divisions",
        allowedRoles: ["Management"],
      },
    ],
  },

  {
    name: "Users",
    allowedRoles: ["Management"],
    submenus: [
      {
        name: "Users",
        menu_url: "/users",
        allowedRoles: ["Management"],
      },
      {
        name: "Cognito",
        menu_url: "/cognitousers",
        allowedRoles: ["Management"],
      },
      {
        name: "Doctors",
        menu_url: "/doctors",
        allowedRoles: ["Management"],
      },
      {
        name: "Pharmacies",
        menu_url: "/pharmacies",
        allowedRoles: ["Management"],
      },
    ],
  },

  {
    name: "Products",
    menu_url: "/products",
    allowedRoles: ["Management", "doctor"],
  },

  {
    name: "Reports",
    allowedRoles: ["Management", "ASM", "Sr Management - HO"],
    submenus: [
      {
        name: "Create Reports",
        menu_url: "/create-reports",
        allowedRoles: ["Management", "ASM", "Sr Management - HO"],
      },
      {
        name: "Generated Reports",
        menu_url: "/generated-reports",
        allowedRoles: ["Management", "ASM", "Sr Management - HO"],
      },
    ],
  },
];

export default Dashboard;
