import React, { useState } from "react";
import Cookies from "universal-cookie";

import "../css/reports.css";
import "../css/createReport.css";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Reports = () => {
  const cookies = new Cookies();

  const [disabled, setDisabled] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();

  return (
    <div>
      <ToastContainer />

      <div className="report_form">
        {/* L0 - L5 Inputs starts */}

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={companyName}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedCompany([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Company" placeholder="Company" />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={statusName}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedStatus([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Status" placeholder="Status" />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={fromDate}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedFromDate([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="From Date"
                placeholder="From Date"
              />
            )}
          />
        </div>

        <div>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            limitTags={1}
            options={toDate}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedToDate([...newValue]);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="To Date" placeholder="To Date" />
            )}
          />
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <Button
          disabled={disabled}
          //   onClick={send_message}
          variant="outlined"
          startIcon={<DescriptionOutlinedIcon />}
        >
          Generate Report
        </Button>
      </div>
    </div>
  );
};

export default Reports;

const companyName = ["Company1", "Company2"];
const statusName = ["Blocked", "Revoked"];
const fromDate = ["in progress1", "in progress2"];
const toDate = ["in progress1", "in progress2"];
