import React, { useMemo, useState, useEffect, useCallback } from "react";
//MRT Imports
import MaterialReactTable from "material-react-table";
//Material-UI Imports
import { Box, Button, Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import { cognito } from "../utils/aws";

import { Container } from "@material-ui/core";
import Cookies from "universal-cookie";
import verifyAccessToken from "../utils/verifyToken";

const GeneratedReports = () => {
  const notify4 = () =>
    toast("Unauthorized access..!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "warning",
    });

  const cookies = new Cookies();

  const [reportsData, setReportsData] = useState([]);
  // const [userId, setUserId] = useState('');
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    getGeneratedReports();
  }, []);

  const getGeneratedReports = async () => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");
    const decodedIdToken = await verifyAccessToken(idToken, "id");
    console.log("decoded => ", decodedIdToken.sub);
    // setUserId(decodedIdToken.sub);
    const response = await fetch(
      `https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/admin/reports?user_id=${decodedIdToken.sub}`,
      {
        method: "GET",
        headers: {
          Authorization: idToken,
        },
      }
    );

    if (response.status === 401) {
      cognito.refreshToken(refreshToken);
      console.log(response);
      getGeneratedReports();
    } else {
      const jsonData = await response.json();
      setReportsData(jsonData.data.reports);
      console.log("Reports data => ", jsonData.data);
      // setLoading(false);
    }
  };
  const handleReportFile = useCallback((row) => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");
    const report_id = row.getValue("report_id");
    fetch(
      `https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/reports/download?filename=${report_id}.xlsx`,
      {
        method: "GET",
        headers: {
          Authorization: idToken,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          cognito.refreshToken(refreshToken);
          console.log(res);
          handleReportFile(row);
        } else {
          return;
        }
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Reports.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        notify4();
      });
  });

  const columns = useMemo(() => [
    {
      id: "company", //id used to define `group` column
      columns: [
        {
          accessorKey: "report_id", //accessorFn used to join multiple data into a single cell
          id: "report_id", //id is still required when using accessorFn instead of accessorKey
          header: "Report Id",
          size: 150,
        },
        {
          accessorKey: "report_url",
          enableClickToCopy: true,
          header: "Report Link",
          minSize: 100, //min size enforced during resizing
          maxSize: 400, //max size enforced during resizing
          size: 180, //medium column
        },
        {
          accessorKey: "parameters.company_id",
          enableClickToCopy: true,
          header: "Company",
          size: 150,
        },
        {
          accessorFn: (row) => {
            return row.parameters.division_id.join(",  ");
          },
          // accessorKey: 'divisions', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Divisions",
          filterFn: "fuzzy",
          size: 150,
        },
        {
          accessorFn: (row) => {
            return row.parameters.state.join(",  ");
          },
          // accessorKey: 'divisions', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "States",
          filterFn: "fuzzy",
          size: 150,
        },
        {
          accessorKey: "parameters.report_type",
          enableClickToCopy: true,
          header: "Report Type",
          size: 150,
        },
        {
          accessorFn: (row) => {
            const date = new Date(row.timestamp);
            const formattedDate = date.toLocaleString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            return formattedDate;
          },
          // accessorKey: 'divisions', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Timestamp",
          filterFn: "fuzzy",
          size: 200,
        },
        {
          accessorKey: "status",
          // filterVariant: 'range',
          header: "Status",
          size: 150,
          //custom conditional format and styling
          Cell: ({ cell }) => (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue() == "Completed"
                    ? theme.palette.success.dark
                    : theme.palette.error.dark,
                borderRadius: "0.25rem",
                color: "#fff",
                maxWidth: "9ch",
                p: "0.25rem",
              })}
            >
              {cell.getValue()?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Box>
          ),
        },
      ],
    },
  ]);

  return (
    <div>
      <ToastContainer />
      <h2 style={{ marginTop: 20, textAlign: "center" }}>Reports</h2>
      <Container>
        <MaterialReactTable
          columns={columns}
          data={reportsData}
          enableColumnFilterModes
          enableColumnOrdering
          enableGrouping
          enablePinning
          // enableRowSelection
          initialState={{
            showColumnFilters: false,
            density: "compact",
            columnVisibility: { report_url: false, report_id: false },
          }}
          positionToolbarAlertBanner="bottom"
          // state={companiesData.length > 0 ? { isLoading: false } : { isLoading: true }}
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#3246c41a",
              },
            },
          }}
          enableRowActions
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="right" title="Download Report">
                <Button
                  disabled={row.getValue("status") !== "Completed"}
                  variant="contained"
                  size="small"
                  color="info"
                  onClick={() => handleReportFile(row)}
                >
                  Download Report
                </Button>
              </Tooltip>
            </Box>
          )}
        />
      </Container>
    </div>
  );
};

export default GeneratedReports;
