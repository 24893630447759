import { CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";


AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  apiVersion: process.env.REACT_APP_AWS_API_VERSION,
  credentials: {
    accessKeyId: 'AKIAW4B7V7RAGBOHUATR',
    secretAccessKey: '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'
  }
})


// const poolData = {
//   UserPoolId: "ap-south-1_MDPyXILYy",
//   ClientId: "vavsjgvje550l9vbmlir3g6fj",
// };

export const DRLUserPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_DRL_USERPOOL_ID,
  ClientId: process.env.REACT_APP_DRL_APPCLIENT_ID,
});

export const CommonUserPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COMMON_USERPOOL_ID,
  ClientId: process.env.REACT_APP_COMMON_APPCLIENT_ID,
});

export const cognitoIdp = new AWS.CognitoIdentityServiceProvider({
  region: process.env.REACT_APP_AWS_REGION,
  apiVersion: process.env.REACT_APP_AWS_API_VERSION,
});

// export default new CognitoUserPool(poolData);
