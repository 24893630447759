import React, { useMemo, useState, useEffect } from "react";
//MRT Imports
import MaterialReactTable from "material-react-table";
//Material-UI Imports
import { Box } from "@mui/material";
import { Container } from "@material-ui/core";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";

import { cognito } from "../utils/aws";

// All employee API
const url =
  "https://9o3ax69w67.execute-api.ap-south-1.amazonaws.com/dev/admin/products";
// const url = "http://localhost:8000/api/admin/products";

const Products = () => {
  const notify = () =>
    toast("Unauthorized access..!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "warning",
    });

  const cookies = new Cookies();

  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProductsData();
  }, [url]);

  const getProductsData = async () => {
    const idToken = cookies.get("idToken");
    const refreshToken = cookies.get("refreshToken");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: idToken,
      },
    });
    if (response.status === 401) {
      cognito.refreshToken(refreshToken);
      console.log(response);
      getProductsData();
    } else {
      const jsonData = await response.json();
      setProductsData(jsonData.data);
      setLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      id: "products", //id used to define `group` column
      columns: [
        {
          accessorKey: "type", //accessorFn used to join multiple data into a single cell
          id: "type", //id is still required when using accessorFn instead of accessorKey
          header: "Type",
          size: 150,
        },
        {
          accessorKey: "name", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Name",
          size: 200,
        },
        {
          accessorKey: "company_id", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Company Id",
          filterFn: "fuzzy",
          size: 200,
        },
        {
          accessorKey: "division_id", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Division Id",
          size: 300,
        },
        {
          accessorKey: "brand_id", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Brand Id",
          filterFn: "fuzzy",
          size: 200,
        },
        {
          accessorKey: "item_molecule", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Item Molecule",
          filterFn: "fuzzy",
          size: 200,
        },
        {
          accessorKey: "active_status",
          // filterVariant: 'range',
          header: "Active Status",
          size: 200,
          //custom conditional format and styling
          Cell: ({ cell }) => (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue() == "N"
                    ? theme.palette.error.dark
                    : theme.palette.success.dark,
                borderRadius: "0.25rem",
                color: "#fff",
                maxWidth: "9ch",
                p: "0.25rem",
              })}
            >
              {cell.getValue()?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Box>
          ),
        },
        {
          accessorKey: "item_UOM", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableClickToCopy: true,
          header: "Item UOM",
          filterFn: "fuzzy",
          size: 200,
        },
      ],
    },
  ]);

  return (
    <div>
      <ToastContainer />
      <h2 style={{ marginTop: 20, textAlign: "center" }}>Products</h2>
      <Container>
        <MaterialReactTable
          columns={columns}
          data={productsData}
          enableColumnFilterModes
          enableColumnOrdering
          enableGrouping
          enablePinning
          enableRowSelection
          initialState={{
            showColumnFilters: false,
            density: "compact",
            columnVisibility: { type: false },
          }}
          positionToolbarAlertBanner="bottom"
          state={
            productsData.length > 0 ? { isLoading: false } : { isLoading: true }
          }
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#3246c41a",
              },
            },
          }}
        />
      </Container>
    </div>
  );
};

export default Products;
