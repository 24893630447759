import {
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { DRLUserPool, CommonUserPool, cognitoIdp } from "./UserPool";
import Cookies from "universal-cookie";

AWS.config.update({
  region: "ap-south-1",
  secretAccessKey: process.env.SECRET_KEY,
  accessKeyId: process.env.ACCESS_KEY,
});

// Create a new CognitoIdentityServiceProvider object
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
const cookies = new Cookies();

function getUserPool(company) {
  switch (company) {
    case "DRL":
      return DRLUserPool;
    default:
      return CommonUserPool;
  }
}

function getClientId(company) {
  switch (company) {
    case "DRL":
      return process.env.REACT_APP_DRL_APPCLIENT_ID;
    default:
      return process.env.REACT_APP_COMMON_APPCLIENT_ID;
  }
}

const getUserRole = (designation) => {
  switch (designation) {
    case "PSR":
      return "L0";
    case "ASM":
      return "L1";
    case "RSM":
      return "L2";
    case "SM":
      return "L3";
    case "NSM":
      return "L4";
    case "DH":
      return "L5";
    case "CH":
      return "L6";
    case "SCH":
      return "L7";
    case "GH":
      return "L8";
    case "SGH":
      return "L9";
    case "MD":
      return "L10";
    default:
      return null;
  }
};

const cognito = {
  signup: (phone, name, company, division, designation, password) => {
    const UserPool = getUserPool(company);
    const role = getUserRole(designation);
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: phone,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "name",
        Value: name,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "custom:company",
        Value: company,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "custom:division",
        Value: division,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "custom:role",
        Value: role,
      })
    );

    return new Promise((resolve, reject) => {
      UserPool.signUp(phone, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  },
  forgotPassword: async (phone, company) => {
    const ClientId = getClientId(company);
    const params = {
      ClientId: ClientId,
      Username: phone,
    };
    return cognitoIdp.forgotPassword(params, (err, data) => {
      if (err) {
        return { success: false, error: err };
      } else {
        return { success: true, data };
      }
    });
  },
  login: (phone, password, company) => {
    const UserPool = getUserPool(company);
    const authenticationData = {
      Username: phone,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: phone,
      Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  },
  logout: (company) => {
    const UserPool = getUserPool(company);
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      return true;
    } else {
      return false;
    }
  },
  refreshToken: async (refreshToken) => {
    const authParams = {
      AuthFlow: "REFRESH_TOKEN",
      ClientId: process.env.REACT_APP_CLIENT_ID,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };

    try {
      const authResult = await cognitoIdentityServiceProvider
        .initiateAuth(authParams)
        .promise();

      const accessToken = authResult.AuthenticationResult.AccessToken;
      const idToken = authResult.AuthenticationResult.IdToken;

      // Use the new access token and id token for API requests
      // ...
      cookies.set("accessToken", accessToken);
      cookies.set("idToken", idToken);
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  },
};

export { cognito };
